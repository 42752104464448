let translation =  {
    translation: {
        mobile: {
            landscape: 'Bitte drehen Sie ihr Gerät in den Portrait-Modus.'
        },
        cookie: {
            ok: 'OK',
            message: 'STRAUSS setzt auf dieser Internetseite Cookies ein. Weitere Informationen finden Sie in unserer <a target="_blank" href="https://www.engelbert-strauss.de/Rechtliches/Cargokonfigurator_Datenschutz">Datenschutzerklärung</a>.'
        },
        splashscreen: {
            title: 'CARGO CONFIGURATOR',
            subtitle: 'MASSGESCHNEIDERTE TASCHEN',
            link: 'JETZT STARTEN',
            store: 'ZUM ONLINE-SHOP',
            storeUrl: 'https://www.engelbert-strauss.de'
        },
        startscreen: {
            title: 'GESTALTEN SIE IHRE INDIVIDUELLEN TASCHEN',
            subtitle: 'Jetzt starten und individuelle Taschenkombinationen zusammenstellen oder bestehendes Design per ID-Code laden.',
            firstpocket: 'Erstellen Sie eine neue Tasche',
            code: {
                placeholder: 'CODE EINGEBEN',
                info: 'Bestehende Konfiguration laden und fortfahren'
            },
            defaultconfigname: 'Neue Tasche'
        },
        notfound: {
            title: 'SEITE NICHT GEFUNDEN',
            subtitle: 'Die Seite die sie aufrufen wollen existiert nicht.<br>Sie haben sich eventuell vertippt oder die Seite wurde verschoben.'
        },
        pocketscreen: {
            title: 'IHRE KONFIGURATION',
            pockets: {
                add: 'Erstellen Sie eine neue Tasche',
                total: 'GESAMT',
                final: 'Ihre Anfrage',
                price: 'Preis'
            },
            incVat: 'mit MwSt.',
            excVat: 'ohne MwSt.',
            share: 'Speichern & teilen Sie ihre Taschen',
            shared: 'Der Code wurde in die Zwischenablage kopiert!',
            info: 'Bitte beachten Sie, dass Ihre Konfiguration nur die Taschenmodule umfasst. Die Basis‐Produkte sind in der Anfrage nicht mit inbegriffen. Sie können diese Artikel in unserem Onlineshop bestellen. Klicken Sie <a href="https://www.engelbert-strauss.de/f/arbeitskleidung/Pflegeeigenschaften:Waschbar_bei_unter_60_C/Taschensysteme:e_s_tool_concept/" target="_blank">hier</a>.',
            overlap: 'Es gibt eine Überlappung von Modulen in einer Ihrer Taschen. Bitte positionieren Sie die Module ohne Überlappung oder entfernen Sie diese Tasche um fortzufahren.',
            shippingTime: 'Voraussichtliche Lieferzeit: 5 Wochen',
            attach: 'anbringen',
            attachEmpty: 'Keine Konfiguration vorhanden'
        },
        editscreen: {
            title: 'IHRE KONFIGURATION',
            name: {
                placeholder: 'Name für Ihre Anpassungen'
            },
            pocket: {
                name: 'GRUNDFLÄCHE',
                left: 'Links',
                right: 'Rechts'
            },
            modules: {
                emptyinfo: 'Fügen Sie Ihre erste Tasche hinzu',
                nofitInfo: 'Wenn ein Taschen-Modul nicht mehr verfügbar ist, um es auf der Konfigurationsfläche hinzuzufügen, ist leider nicht genügend Platz vorhanden',
                info: 'IHRE TASCHENMODULE',
                add: 'WEITERE',
                total: 'GESAMT',
                save: 'SPEICHERN',
                cancel: 'VERWERFEN',
                capacity: 'Kapazität',
                capacityUnit: 'cm',
                size: 'Größe',
                sizeUnit: 'cm'
            },
            print: {
                button: 'Drucken',
                title: 'TASCHEN-KONFIGURATION AUSDRUCKEN',
                subtitle: 'Drucken Sie Ihre Konfiguration in Echtgröße',
                settings: 'Achten Sie für ein optimales Ergebnis darauf, in den Druckeinstellungen das Format DIN A3 und die Option "Hintergrundgrafiken" auszuwählen. Die Skalierung sollte auf 100% gesetzt sein.',
                page: {
                    title: 'CI Configurator',
                    code: 'Code'
                }
            },
            move: 'BEWEGEN',
            scale: 'SKALIEREN',
            height: 'HÖHE',
            volume: 'VOLUMEN',
            extend: 'ERWEITERN',
            delete: 'LÖSCHEN',
            preview: 'Vorschau',
            detail: 'Detail',
            overview: 'Übersicht'
        },
        finalscreen: {
            title: 'Kontaktinformationen',
            back: 'Zurück',
            commit: 'Absenden',
            total: 'Gesamtpreis inkl. {{shipping}} Versand',
            contact: {
                orderFor: 'Bestellung für:',
                customerNumber: 'Kundennummer (optional)',
                firstName: 'Vorname',
                lastName: 'Nachname',
                companyName: 'Firmenname',
                companyContact: 'Ansprechpartner',
                country: 'Land',
                zipCode: 'PLZ',
                city: 'Ort',
                address: 'Adresse',
                title: 'Anrede',
                occupation: 'Berufssektor (optional)',
                email: 'E-Mail Adresse',
                phone: 'Telefonnummer',
                comment: 'Kommentar (optional)',
                titleFemale: 'Frau',
                titleMale: 'Herr'
            },
            orderFor: {
                'company': 'Firma',
                'practice': 'Praxis',
                'private': 'Privat'
            },
            info: 'Bitte beachten: Nach Absenden Ihrer Bestellungen erhalten Sie zunächst eine Eingangsbestätigung mit den Informationen zur Ihrer individuellen Taschenkonfiguration. Wir kontaktieren Sie im Anschluss zur Auftragsbestätigung samt Zahlungsinformationen per E-Mail.',
            policy: {
                text: 'Ich habe die <a target="_blank" href="https://www.engelbert-strauss.de/Rechtliches/AGB/Cargokonfigurator">Allgemeinen Geschäftsbedingungen</a> für den CI Cargo-Konfigurator gelesen und akzeptiere sie.',
                accept: 'Ich akzeptiere.'
            },
        },
        postscreen: {
            title: 'VIELEN DANK FÜR IHRE ANFRAGE',
            summary: 'Wir prüfen Ihre individuelle Konfiguration und melden uns schnellstmöglich bei Ihnen zurück.',
            questions: 'Haben Sie vorab Fragen oder Anmerkungen? Dann schreiben Sie uns per E-Mail an ci.configurator.cargo@strauss.de',
            overview: 'Übersicht',
            new: 'Neue Konfiguration starten',
            commit: 'Zum Online-Shop',
            total: 'Gesamtpreis',
        },
        infoscreen: {
            title: 'SO FUNKTIONIERT\'S',
            subtitle: 'Ganz einfach zur individuellen Cargo‐Tasche für Ihr Produkt. Und so funktioniert\'s:',
            text1: 'Wählen Sie eine Seite aus, auf der Sie Ihre individuellen Module auf der Klettfläche platzieren möchten',
            text2: 'Wählen Sie Module aus, die genau zu Ihren Anforderungen passen.',
            text3: 'Passen Sie die Module auf die gewünschten Abmessungen an und vervollständigen Sie Ihre einzigartige Taschenkombination.',
            questions: 'Haben Sie vorab Fragen oder Anmerkungen?',
            phone: {
                text: 'Unser Service-Team hilft Ihnen gerne weiter. Rufen Sie uns an!',
                numberDisplay: '0 60 50 / 97 10 12',
                number: '+496050971012'
            },
            mail: {
                text: 'Oder schreiben Sie uns eine E-Mail. Wir werden so schnell wie möglich antworten.',
                addressDisplay: 'ci.configurator.cargo@strauss.de',
                address: 'ci.configurator.cargo@strauss.de'
            }
        },
        exitscreen: {
            title: 'KONFIGURATOR BEENDEN',
            text: 'Möchten Sie den Konfigurator wirklich verlassen? Sie können Ihre aktuelle Konfiguration mit Ihrem Erstellercode unten speichern:',
            cancel: 'ABBRECHEN',
            quit: 'VERLASSEN'
        },
        linkfooter: {
            home: 'STRAUSS',
            impressum: 'Impressum',
            eula: 'AGB',
            gdpr: 'Datenschutz',
            osplatform: 'OS-Plattform'
        },
        logo: {
            title: 'CI CONFIGURATOR'
        },
        misc: {
            copySuffix: ' (Kopie)'
        },
        errors: {
            network: 'Fehler beim Verbinden mit dem Server!',
            notfound: 'Dieser Code wurde leider nicht gefunden!',
            unauthorized: 'You are not authorized to access this resource.'
        }
    }
};

export default translation;
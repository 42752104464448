let translation =  {
    translation: {
        mobile: {
            landscape: 'Please switch your device into portrait mode.'
        },
        cookie: {
            ok: 'OK',
            message: 'STRAUSS is using cookies on this page. You can find more information on our <a target="_blank" href="https://www.engelbert-strauss.de/Rechtliches/Cargokonfigurator_Datenschutz">data protection page</a>.'
        },
        splashscreen: {
            title: 'CREATE YOUR OWN DESIGN',
            subtitle: 'TAILORMADE POCKETS',
            link: 'START CUSTOMIZING',
            store: 'ONLINE-SHOP',
            storeUrl: 'https://www.engelbert-strauss.com'
        },
        startscreen: {
            title: 'DESIGN YOUR INDIVIDUAL POCKETS',
            subtitle: 'Start now and put together individual pocket combinations or load an existing design using an ID code.',
            firstpocket: 'Create a new pocket',
            code: {
                placeholder: 'ENTER YOUR CODE',
                info: 'Load existing configuration and continue'
            },
            defaultconfigname: 'New Pocket'
        },
        notfound: {
            title: 'PAGE NOT FOUND',
            subtitle: 'The page you are trying to access is unavailable. You might have mistyped the address or the page was moved.'
        },
        pocketscreen: {
            title: 'YOUR CONFIGURATION',
            pockets: {
                add: 'Create a new pocket',
                total: 'TOTAL',
                final: 'Final details',
                price: 'Price'
            },
            incVat: 'inc Vat',
            excVat: 'ex Vat',
            share: 'Save & share your pockets',
            shared: 'The code was copied into the clipboard!',
            info: 'Please note that your configuration only includes the pocket modules. The basic products are not included in the request. You can order these items in our online shop. Click <a href="https://www.engelbert-strauss.de/en/f/workwear-safetywear/Bag_systems:e_s_tool_concept/Care_properties:Washable_below_60_C/" target="_blank">here</a>.',
            overlap: 'There is overlap in one of your pockets. Please reposition the modules without overlap or remove the pocket to continue.',
            shippingTime: 'Estimated delivery time: 5 weeks',
            attach: 'attach',
            attachEmpty: 'No configuration applicable'
        },
        editscreen: {
            title: 'YOUR CONFIGURATION',
            name: {
                placeholder: 'Name of your configuration'
            },
            pocket: {
                name: 'BASE',
                left: 'Left',
                right: 'Right'
            },
            modules: {
                emptyinfo: 'Add your firtst pocket module',
                nofitInfo: 'Unfortunately, if a pocket module is no longer available to add it to the configuration area, there is not enough space.',
                info: 'YOUR POCKET MODULES',
                add: 'ADD',
                total: 'TOTAL',
                save: 'SAVE',
                cancel: 'CANCEL',
                capacity: 'Volume',
                capacityUnit: 'cm',
                size: 'Size',
                sizeUnit: 'cm'
            },
            print: {
                button: 'Print',
                title: 'PRINT YOUR POCKET CONFIGURATION',                
                subtitle: 'Print your configuration in real size',
                settings: 'For the best result, make sure to select the DIN A3 format and the "Background graphics" option in the print settings. The scaling should be set to 100%.',
                page: {
                    title: 'CI Configurator',
                    code: 'Code'
                }
            },
            move: 'MOVE',
            scale: 'SCALE',
            height: 'HEIGHT',
            volume: 'VOLUME',
            extend: 'EXTEND',
            delete: 'DELETE',
            preview: 'Preview',
            detail: 'Detail',
            overview: 'Overview'
        },
        finalscreen: {
            title: 'Contact Details',
            back: 'Back',
            commit: 'Place request',
            total: 'Total Price (inc. {{shipping}} shipping)',
            contact: {
                orderFor: 'Order for:',
                customerNumber: 'Customer number (optional)',
                firstName: 'First name',
                lastName: 'Last name',
                companyName: 'Company name',
                companyContact: 'Contact person',
                country: 'Country',
                zipCode: 'Postcode',
                city: 'Location',
                address: 'Address',
                title: 'Title',
                occupation: 'Professional sector (optional)',
                email: 'Email address',
                phone: 'Phone number',
                comment: 'Comment (optional)',
                titleFemale: 'Ms',
                titleMale: 'Mr'
            },
            orderFor: {
                'company': 'Company',
                'practice': 'Practice',
                'private': 'Private'
            },
            info: 'Please note: After submitting your order, you will first receive a confirmation of receipt with information on your individual bag configuration. We will then contact you by e-mail to confirm the order and include payment information.',
            policy: {
                text: 'I have read the <a target="_blank" href="https://www.engelbert-strauss.de/Rechtliches/AGB/Cargokonfigurator">General Terms and Conditions</a> for the “CI Cargo Configurator” and accept them.',
                accept: 'I accept.'
            }
        },
        postscreen: {
            title: 'THANK YOU FOR YOUR REQUEST',
            summary: 'We will check your individual configuration and get back to you as soon as possible.',
            questions: 'Do you have any questions or comments in advance? Then send us an email to ci.configurator.cargo@strauss.de',
            overview: 'Request Overview',
            new: 'Start new configuration',
            commit: 'Go to Online Shop',
            total: 'Total Price'
        },
        infoscreen: {
            title: 'HOW IT WORKS',
            subtitle: 'Take a tour through the configurator. See how easy it works.',
            text1: 'Select a side on which you would like to place your individual modules.',
            text2: 'Choose modules from our list and places them to your pocket.',
            text3: 'Customise the modules to suit your needs and complete your perfect bag.',
            questions: 'Do you have any questions or comments in advance?',
            phone: {
                text: 'Call us. our service team will be happy to help you.',
                numberDisplay: '0 60 50 / 97 10 12',
                number: '+496050971012'
            },
            mail: {
                text: 'Or write us an email. We\'ll Response as quick as we can.',
                addressDisplay: 'ci.configurator.cargo@strauss.de',
                address: 'ci.configurator.cargo@strauss.de'
            }
        },
        exitscreen: {
            title: 'QUIT CONFIGURATOR',
            text: 'Are you sure to quit the configurator? You can save your actual configuration with your creator Code below:',
            cancel: 'CANCEL',
            quit: 'QUIT'
        },
        linkfooter: {
            home: 'STRAUSS',
            impressum: 'Imprint',
            eula: 'Terms and conditions',
            gdpr: 'Data protection',
            osplatform: 'OS-Platform'
        },
        logo: {
            title: 'CI CONFIGURATOR'
        },
        misc: {
            copySuffix: ' (Copy)'
        },
        errors: {
            network: 'Failed to connect to server!',
            notfound: 'This code was not found!',
            unauthorized: 'You are not authorized to access this resource.'
        }
    }
};

export default translation;